.cardsContainer {
    display: flex;
}

.buttonText{
    font-size: 0.8rem !important;
}

.aPCard {
    height: 100%;
    margin-top: 2rem;
    border: 3px solid #00406E !important;
    min-height : 400px;
}

.aPTitle {
    font-family: Roboto;
    text-transform: uppercase;
    margin-bottom: 0.1rem !important
}

.aPSubtitle {
    font-family: Roboto;
    margin-top: 0 !important;
    margin-bottom: 1rem
}

.titleGrid {
    display: flex;
    justify-content: center;
    align-items: center
}

.itemCardGrid {
    display: flex;
    justify-content: center;
    align-items: center
}

.clickableTitle {
    cursor: pointer;
    max-height: 100%;
}

.clickableTitle:hover .MuiPaper-outlined {
    background-color: rgba(50, 50, 50, 0.1) !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
}

.aPDescription {
    font-family: Roboto;
    letter-spacing: 1.25px;
    font-size: 2rem;
    margin-top: 0;
    min-height : 74px;
}

.cardActions {
    display: flex;
    align-items: center;
    justify-content: center
}

.cardAction {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%
}

.buttonAction {
    width: 100%;
    background-color: #00406E !important;
    color: white !important;
}

.buttonAction:hover {
    background-color: #00406e85 !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
}

.buttonNormalCase {
    text-transform: none !important;
}

.loginIcon {
    transform: scale(3);
    color: #8A0857
}