/* Provide sufficient contrast against white background */

p {
  font-family: Roboto;
}

li {
  font-family: Montserrat;
}

h1 {
  font-family: Montserrat;
}

a {
  color: #0366d6;
  font-family: Roboto;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

html, body, #root, .App {
  margin: 0;
}

.pageContainer {
    position: relative;
    min-height: 100vh;
}