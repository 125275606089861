@media screen and (min-height: 801px) {
    .footerBox {
        background-color: #00406E;
        background-size: cover;
        width: 100%;
        min-height: 2.5rem;
        bottom: 0;
        margin-top: 1.5em;
        display: flex;
        align-items: center;
        position: absolute;
    }
}

@media screen and (max-height: 800px) {
    .footerBox {
        background-color: #00406E;
        background-size: cover;
        width: 100%;
        min-height: 2.5rem;
        bottom: 0;
        margin-top: 1.5em;
        display: flex;
        align-items: center;
    }
}

.footerBox > p {
    margin: 2vh;
    color: white;
    letter-spacing: 1.25px;
    font-size: 1rem;
}

.footerBox > p > a {
    color: orange;
}
